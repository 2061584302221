import React, { useState } from 'react';
import Button from '../../Button/Button';
import Icon from '../../Icon/Icon';
import PulseNotificationIcon from '../../PulseNotificationIcon/PulseNotificationIcon';
import FlightChatRoles from './FlightChatRoles';
import classNames from 'classnames';
import ChatWindow from './ChatWindow/ChatWindow';
import {
  ALL_CHATS,
  CHAT_TOPIC_SEPARATOR,
  DEVICE_TYPE,
  FLIGHT_CHAT,
  MY_ROLE,
  TEAM_CHAT,
} from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectCheckedInUsers } from '../../../redux/reducers/checkinReducer';
import {
  selectChatMessages,
  selectLastStatusUpdate,
  updateMessageStatus,
} from '../../../redux/reducers/chatReducer';
import { PhoneNumber } from '../../StationContact/Phone';
import { useToggle } from '../../../utils/hooks/useToggle';
import {
  capitalizeFirstLetter,
  getCarrierFromFlighId,
  getFlightName,
  getUnreadMessagesCountByChat,
  setTimeFormat,
} from '../../../utils/helpers';
import {
  select24Format,
  selectUTC,
} from '../../../redux/reducers/settingsReducer';
import { useMarkChatAsRead } from '../../../utils/hooks/useMarkChatAsRead';
import { MessageStatus, Permission } from '../../../utils/generated/graphql';
import { useDeviceBreakpoint } from '../../../utils/hooks/useDeviceBreakpoint';
import ListHeader from '../ChatHeader/ChatHeader';
import useCheckPermissions, {
  CheckType,
} from '../../../utils/hooks/useCheckPermissions';
import ChatCount from '../ChatHeader/ChatCount';

interface IFlightChatContainer {
  handleCloseChat: () => void;
  handleBack: () => void;
  flightId: string;
}

export interface ChatIdState {
  chatId: string;
  lastConversationId?: string;
}

const findCurrentConversation = (
  chatMessages,
  flightId,
  chatRoleGroup,
  currentRole
) => {
  return chatMessages?.conversations?.find((conv) => {
    if (!conv || !conv?.flightId) {
      return false;
    }
    const [roles, _] = conv.chatId?.split(CHAT_TOPIC_SEPARATOR) ?? '';
    const convRolesArray = roles.split('-');
    const convRoles = convRolesArray.map((role) => role.toLowerCase());
    if (chatRoleGroup === TEAM_CHAT.roleGroup) {
      return (
        conv.flightId === flightId &&
        convRoles.some((fullRole: string) =>
          fullRole.includes(chatRoleGroup?.toLowerCase() ?? '')
        )
      );
    }

    return (
      conv.flightId === flightId &&
      convRoles.some((fullRole: string) =>
        fullRole.includes(chatRoleGroup?.toLowerCase() ?? '')
      ) &&
      convRoles.some((fullRole: string) =>
        fullRole.includes(currentRole?.toLowerCase() ?? '')
      )
    );
  });
};

const getConversationIds = (currentConversation) => {
  const conversationIdsSet = currentConversation?.messages?.reduce(
    (conversationIds, currentMessage) => {
      conversationIds.add(currentMessage.conversationId);
      return conversationIds;
    },
    new Set()
  );
  return conversationIdsSet ? [...conversationIdsSet] : [];
};

const FlightChatContainer = ({
  handleCloseChat,
  handleBack,
  flightId,
}: IFlightChatContainer) => {
  const { markChatAsRead } = useMarkChatAsRead();
  const lastStatusUpdate = useSelector(selectLastStatusUpdate);
  const chatMessages = useSelector(selectChatMessages);
  const rolesListFlight = useSelector(selectCheckedInUsers);
  const isUTC = useSelector(selectUTC);
  const is24Format = useSelector(select24Format);

  const [showQR, setShowQR] = useToggle(false);

  const currentRoleId = rolesListFlight[flightId]?.myRole?._id;
  const hasTeamChatPermission =
    useCheckPermissions([Permission.CHAT_GROUP], CheckType.FULL, [
      currentRoleId,
    ]) && rolesListFlight[flightId]?.roles.length > 1;

  const rolesList =
    rolesListFlight[flightId]?.roles
      ?.filter((role) => role.canChat)
      ?.map((role) => {
        return {
          roleGroup: role.roleGroup,
          roleName: role.name,
          rolesCheckedIn: role.checkedInUsers,
        };
      }) ?? [];
  const currentRole = rolesListFlight[flightId]?.myRole;

  const totalRolesCheckedIn = rolesListFlight[flightId]?.roles
    .map((role) => role.checkedInUsers.length)
    .reduce((acc, roles) => acc + roles, 0);

  if (hasTeamChatPermission) {
    rolesList.unshift({
      roleGroup: TEAM_CHAT.roleGroup,
      roleName: TEAM_CHAT.roleName,
      rolesCheckedIn: [],
    });
  }

  const [activeChat, setActiveChat] = useState<string | null>(null);
  const [chatDetails, setChatDetails] = useState<ChatIdState>({
    chatId: '',
    lastConversationId: '',
  });
  const isMobile = useDeviceBreakpoint(DEVICE_TYPE.MOBILE);
  const dispatch = useDispatch();

  const flightName = getFlightName(flightId);
  const [showPhone, toggle] = useToggle(false);

  const checkedInUsersForCurrentChat = rolesListFlight[flightId]?.roles?.find(
    (role) => role.roleGroup === activeChat
  )?.checkedInUsers;

  const chatRoleTitle = rolesListFlight[flightId]?.roles?.find(
    (role) => role.roleGroup === activeChat
  )?.name;

  const activeChatPhoneNumbers = checkedInUsersForCurrentChat
    ?.filter((user) => user.phone !== '')
    .map((user) => user.phone);

  const rolesCheckedIn = checkedInUsersForCurrentChat?.length;

  const isPhoneAvailable = activeChatPhoneNumbers?.length;

  const isSingleChatParticipant = rolesCheckedIn === 1;

  const chatTitle = !activeChat
    ? flightName
    : activeChat === TEAM_CHAT.roleGroup
    ? capitalizeFirstLetter(TEAM_CHAT.roleName.toLowerCase())
    : chatRoleTitle;

  const markMessagesAsRead = async () => {
    const currentConversation = findCurrentConversation(
      chatMessages,
      flightId,
      activeChat,
      currentRole?.roleGroup
    );
    const conversationIds = getConversationIds(currentConversation);
    const unreadMessages = currentConversation?.messages?.some(
      (msg) => !msg.isMe && msg.status !== MessageStatus.READ
    );
    if (lastStatusUpdate && unreadMessages) {
      const response = await markChatAsRead(
        lastStatusUpdate,
        conversationIds,
        currentConversation?.chatId || ''
      );
      if (response?.success) {
        currentConversation?.messages?.forEach((message) => {
          if (!message.isMe && message.status !== MessageStatus.READ) {
            dispatch(
              updateMessageStatus({
                chatId: currentConversation?.chatId,
                status: MessageStatus.READ,
                message: message.body,
                messageId: message.messageId,
              })
            );
          }
        });
      }
    }
  };

  const handleChatSelect = (chatRoleGroup: string | null) => {
    setActiveChat(chatRoleGroup);
    const currentConversation = findCurrentConversation(
      chatMessages,
      flightId,
      chatRoleGroup,
      currentRole?.roleGroup
    );
    setChatDetails({
      chatId: currentConversation?.chatId ?? '',
      lastConversationId: currentConversation?.lastConversationId ?? '',
    });
    markMessagesAsRead();
  };

  return (
    <div className="h-screen flex flex-col">
      <ListHeader handleClose={handleCloseChat}>
        <Button
          text={ALL_CHATS.MY_CHATS}
          Icon={
            <Icon
              height={12}
              width={12}
              variant="arrowBack"
              className="fill-primary dark:fill-white"
            />
          }
          className="flex items-center gap-8 mobile:hidden"
          textClassName="text-primary font-head text-14 dark:text-white uppercase font-semibold"
          onClick={handleBack}
        />
        <Button
          Icon={
            <Icon
              height={14}
              width={14}
              variant="arrowBack"
              className="fill-primary dark:fill-white"
            />
          }
          className="flex items-center gap-8 tablet:hidden laptop:hidden desktop:hidden"
          textClassName="text-primary font-head-bold text-14 dark:text-white"
          onClick={
            !!activeChat
              ? () => {
                  handleChatSelect(null);
                  showPhone && toggle();
                }
              : handleBack
          }
        />
        <div className="flex flex-col items-center mobile:w-full">
          <div className="flex gap-8 items-center -mt-8">
            <div className="text-primary text-16 font-head-bold dark:text-grey-12">
              <span className="mobile:hidden">{flightName}</span>
              <span className="tablet:hidden laptop:hidden desktop:hidden">
                {chatTitle}
              </span>
            </div>
            {!activeChat ? (
              <PulseNotificationIcon />
            ) : (
              !!checkedInUsersForCurrentChat?.length && (
                <PulseNotificationIcon />
              )
            )}
          </div>
          <div className="text-grey-60 dark:text-white">
            {MY_ROLE}: {currentRole?.name}
          </div>
        </div>
        {!!activeChat && activeChat !== TEAM_CHAT.roleGroup && (
          <div className="flex items-center mr-5 tablet:hidden laptop:hidden desktop:hidden">
            <ChatCount
              key={activeChat}
              title={TEAM_CHAT.roleGroup}
              rolesCheckedIn={rolesCheckedIn}
              isOnlyOneRole={!!isSingleChatParticipant}
              isPhoneAvailable={!!isPhoneAvailable}
              toggle={() => toggle()}
            />
          </div>
        )}
        {showPhone && (
          <ul className="w-fit absolute z-50 right-[24px] top-[60px] flex flex-col">
            {activeChatPhoneNumbers?.map((number) => (
              <li
                key={number}
                className="cursor-default flex justify-around font-head-light bg-white dark:bg-grey-90 text-primary dark:text-grey-12 text-16 text-center py-4 px-[8px] rounded-8 border-primary dark:border-grey-12 border-1 border-opacity-25 ">
                <PhoneNumber
                  number={number}
                  showCallIcon={true}
                  showQR={showQR}
                  setShowQR={setShowQR}
                />
              </li>
            ))}
          </ul>
        )}
      </ListHeader>
      <div className="flex flex-1 overflow-y-hidden dark:bg-grey-90">
        <FlightChatRoles
          className={classNames('w-[30%] mobile:w-full dark:bg-grey-90', {
            'mobile:hidden': activeChat,
          })}
          handleChatSelect={handleChatSelect}
          activeChat={activeChat}
          totalRolesCheckedIn={totalRolesCheckedIn}
          chats={rolesList
            .filter(({ roleGroup }) => roleGroup !== currentRole.roleGroup)
            .map(({ roleGroup, roleName, rolesCheckedIn }) => {
              const flightRoles = rolesListFlight[flightId]?.roles;
              const flightRole = flightRoles?.find(
                (flightRole) => flightRole.roleGroup === roleGroup
              )?.checkedInUsers;
              const currentConversation = findCurrentConversation(
                chatMessages,
                flightId,
                roleGroup,
                currentRole?.roleGroup
              );
              const lastMessage =
                currentConversation &&
                currentConversation?.messages?.[
                  currentConversation?.messages?.length - 1
                ];
              const unreadMessagesCount =
                getUnreadMessagesCountByChat(currentConversation);

              return {
                roleName,
                roleGroup,
                messagesCount: unreadMessagesCount,
                isParticipantCheckedIn: !!flightRole?.length,
                phoneNumbers: flightRole?.map((role) => role.phone) ?? [],
                message: {
                  text: lastMessage?.body,
                  time: setTimeFormat({
                    getTime: lastMessage?.createdAt,
                    utcFormat: isUTC,
                    format24: is24Format,
                    getTimeFormat: 'YYYY-MM-DDTHH:mm:ssZ',
                    hasAMPM: true,
                  }),
                  role: lastMessage?.userName,
                },
                rolesCheckedIn: rolesCheckedIn.length,
              };
            })}
        />
        {!isMobile && (
          <div className="w-[70%] border-l-1 border-b-grey-12 h-full flex justify-center">
            {activeChat ? (
              <ChatWindow
                currentUserRole={currentRole?.roleGroup}
                currentUserChatName={currentRole?.shortName ?? ''}
                currentUserTechicalRole={`TAC_${getCarrierFromFlighId(
                  flightId
                )}_${currentRole?.roleGroup}`}
                activeChatTechnicalRole={`TAC_${getCarrierFromFlighId(
                  flightId
                )}_${activeChat}`}
                flightId={flightId}
                chatDetails={chatDetails}
                markMessagesAsRead={markMessagesAsRead}
              />
            ) : (
              <div className="text-grey-60 text-24 font-body-bold self-center dark:text-grey-25">
                {FLIGHT_CHAT.NO_CHATS}
              </div>
            )}
          </div>
        )}
        {isMobile && activeChat && (
          <ChatWindow
            currentUserRole={currentRole?.roleGroup}
            currentUserChatName={currentRole?.shortName ?? ''}
            currentUserTechicalRole={`TAC_${getCarrierFromFlighId(flightId)}_${
              currentRole?.roleGroup
            }`}
            activeChatTechnicalRole={`TAC_${getCarrierFromFlighId(
              flightId
            )}_${activeChat}`}
            flightId={flightId}
            chatDetails={chatDetails}
            markMessagesAsRead={markMessagesAsRead}
          />
        )}
      </div>
    </div>
  );
};

export default FlightChatContainer;
