import { useLazyQuery } from '@apollo/client';
import { Query, SendPushNotificationOutput } from '../generated/graphql';
import { IDataResponse } from '../types';
import { SEND_PUSH_NOTIFICATION } from '../../graphql/pushNotifications/pushNotifications.queries';

interface ISendPushNotificationLazy
  extends IDataResponse<SendPushNotificationOutput> {
  onSendPushNotification: () => Promise<SendPushNotificationOutput>;
}
export const useSendPushNotificationLazy = (): ISendPushNotificationLazy => {
  const [sendPushNotification, { data, loading, error }] = useLazyQuery<
    Pick<Query, 'sendPushNotification'>
  >(SEND_PUSH_NOTIFICATION);

  const onSendPushNotification = async () => {
    const result = await sendPushNotification();

    const emptyResult: SendPushNotificationOutput = {
      success: false,
      errors: [],
    };
    return result?.data?.sendPushNotification ?? emptyResult;
  };
  return {
    data: data?.sendPushNotification ?? null,
    loading,
    error,
    onSendPushNotification,
  };
};
