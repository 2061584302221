import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import {
  selectAuthReducer,
  selectUserId,
  selectIsFirstLogin,
  setIsFirstLogin,
} from '../../redux/reducers/authReducer';
import { useGetUserSettings } from '../../utils/hooks/useGetUserSettings';
import { useUpdateUserSettings } from '../../utils/hooks/useUpdateUserSettings';
import {
  getUserSettingsFailure,
  select24Format,
  selectStationsList,
  selectUTC,
  setUserSettingsFailure,
  setUserSettingsStart,
  updateUserSettingsSuccess,
} from '../../redux/reducers/settingsReducer';
import { AUTOMATIC, ERRORS, TIMEZONE_SETTINGS } from '../../utils/constants';
import {
  Permission,
  UpdateSettingsLocations,
  UserSettingsInput,
  UserSettingsOutput,
} from '../../utils/generated/graphql';
import { addError } from '../../redux/reducers/notificationReducer';
import UtilFactory from '../../factory/UtilFactory';
import { MQTT_TOPIC } from '../../models/MqttTopics';
import { handleNewNotification } from '../../utils/notifications/notificationsUtil';
import { addNotification } from '../../redux/reducers/newsfeedReducer';
import { useNotifications } from '../../utils/hooks/useNotifications';
import useCheckPermissions from '../../utils/hooks/useCheckPermissions';
import { useGetTelephonyBook } from '../../utils/hooks/useGetTelephonyBook';
import { setStation } from '../../redux/reducers/telephoneBookReducer';
import useCheckTechnicalRole from '../../utils/hooks/useCheckRolePermissions';
import useNetworkStatus from '../../utils/hooks/useNetworkStatus';

interface IUserSettingsProps {
  loggedIn: boolean;
  children: ReactElement;
}

const UserSettings: React.FC<IUserSettingsProps> = (
  props: IUserSettingsProps
) => {
  const { loggedIn, children } = props;
  const dispatch = useDispatch<AppDispatch>();
  const userId = useSelector(selectUserId);
  const useUTC = useSelector(selectUTC);
  const use24 = useSelector(select24Format);
  const tokenData = useSelector(selectAuthReducer);
  const { data, loading, error } = useGetUserSettings({
    skip: !loggedIn,
  });
  const [userSettingsList, setUserSettingsList] =
    useState<UserSettingsOutput | null>(null);

  const hasStationNotificationAccess = useCheckPermissions([
    Permission.RECEIVE_STATION_NOTIFICATIONS,
  ]);

  const hasRoleNotificationAccess = useCheckPermissions([
    Permission.RECEIVE_ROLE_NOTIFICATIONS,
  ]);

  const isCockpit = useCheckTechnicalRole('TAC_LH_COCKPIT');

  const userStations = useSelector(selectStationsList);

  const stationsList = isCockpit ? [] : ['General', ...userStations];
  const { data: dataStation, loading: loadingStation } =
    useGetTelephonyBook(stationsList);
  const { onUpdateUserSettings, responseErrors } = useUpdateUserSettings();
  useNotifications(responseErrors?.[0], responseErrors?.[0]?.message);

  const userPhoneNumber =
    data?.phoneNumber ?? tokenData.tokenPhone?.replace(/\s/g, '') ?? '';

  const onMessage = async (topic: string, message: string) => {
    handleNewNotification(
      message,
      userId,
      (userNotification) => dispatch(addNotification(userNotification)),
      useUTC,
      use24
    );
    try {
    } catch (error) {
      console.error('Could not parse notification');
      console.error(error);
    }
  };

  const isFirstLogin = useSelector(selectIsFirstLogin);
  const hasChatReadPermission = useCheckPermissions([Permission.CHAT_READ]);
  const isOnline = useNetworkStatus();

  const updateUserSettings =
    (data: UserSettingsInput): ((dispatch: AppDispatch) => Promise<void>) =>
    async (dispatch: AppDispatch) => {
      dispatch(setUserSettingsStart());
      try {
        const result = await onUpdateUserSettings(
          !hasChatReadPermission && !data.chatMuted
            ? { ...data, chatMuted: true }
            : data,
          isFirstLogin
            ? UpdateSettingsLocations.LOGIN
            : UpdateSettingsLocations.SETTINGS
        );
        dispatch(setIsFirstLogin(false));
        dispatch(updateUserSettingsSuccess(result));
        setUserSettingsList(result);
      } catch (error) {
        dispatch(setUserSettingsFailure(ERRORS.COULD_NOT_SAVE));
      }
    };

  useEffect(() => {
    if (
      process?.env?.REACT_APP_TAC_ADMIN_PAGE_FEATURE_DISABLED?.toLocaleLowerCase() !==
        'true' &&
      hasStationNotificationAccess
    ) {
      userSettingsList?.stationsList.forEach((station) => {
        UtilFactory.newMessageHandler().subscribe({
          topic: `${MQTT_TOPIC.STATION}${station}`,
          onMessage,
        });
      });
    }

    if (
      process?.env?.REACT_APP_TAC_ADMIN_PAGE_FEATURE_DISABLED?.toLocaleLowerCase() !==
        'true' &&
      hasRoleNotificationAccess
    ) {
      userSettingsList?.roles.forEach((role) => {
        UtilFactory.newMessageHandler().subscribe({
          topic: `${MQTT_TOPIC.ROLE}${role.roleGroup}`,
          onMessage,
        });
      });
    }
  }, [
    userSettingsList,
    hasStationNotificationAccess,
    hasRoleNotificationAccess,
  ]);
  useEffect(() => {
    const isLoading = isOnline && loadingStation;
    if (loggedIn && !isLoading && dataStation) {
      dispatch(setStation(dataStation));
    }
  }, [dataStation, dispatch, isOnline, loadingStation, loggedIn, stationsList]);

  useEffect(() => {
    if (loggedIn && !loading && error) {
      dispatch(addError(ERRORS.ERROR_GET_SETTINGS));
    } else if (loggedIn && !loading && !error) {
      try {
        const stations: string[] = data
          ? data.stationsList?.length !== 0
            ? data?.stationsList
            : data?.station !== '' && data?.station !== null
            ? [data?.station ?? '']
            : []
          : [];

        const settings = {
          userName: userId,
          firstName: data?.firstName ?? tokenData.tokenFirstName ?? '',
          name: tokenData.tokenName ?? '',
          phoneNumber: userPhoneNumber,
          email: tokenData.tokenEmail ?? '',
          chatMuted: data?.chatMuted ?? false,
          stationsList: stations,
          station: data?.station ?? '',
          theme: data?.theme ?? AUTOMATIC,
          useUTC: data?.useUTC ?? TIMEZONE_SETTINGS.SET_UTC,
          use24: data?.use24 ?? TIMEZONE_SETTINGS.SET_24,
          stationMuted: data?.stationMuted ?? false,
          favoriteFlightsMuted: data?.favoriteFlightsMuted ?? false,
          nativeNotificationsMuted: data?.nativeNotificationsMuted ?? false,
          isPhoneReminderActive: !userPhoneNumber,
        };

        dispatch(updateUserSettings(settings));
      } catch (e) {
        dispatch(getUserSettingsFailure(e.message));
      }
    }
  }, [loading]);

  return children;
};

export default UserSettings;
