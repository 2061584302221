import React, { ReactNode, useEffect, useState } from 'react';
import './globals.scss';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from 'react-router-dom';
import Layout from './Layout';
import Login from './pages/Login/Login';
import Process from './pages/Process/Process';
import AboveTheWing from './pages/AboveTheWing/AboveTheWing';
import BelowTheWing from './pages/BelowTheWing/BelowTheWing';
import Home from './pages/Home/Home';
import Team from './pages/Team/Team';
import Settings from './pages/Settings/Settings';
import useDarkMode from './utils/hooks/useDarkMode';
import Favourites from './pages/Favourites/Favourites';
import FlightSearchContainer from './pages/FlightSearchContainer/FlightSearchContainer';
import AppVersion from './components/AppVersion/AppVersion';
import { ApolloProvider } from '@apollo/client';
import Mqtt from './components/Mqtt/Mqtt';
import UserSettings from './components/userSettings/userSettings';
import Admin from './pages/Admin/Admin';
import { APP_NAME } from './utils/constants';
import { isUserLoggedIn } from './utils/helpers';
import useCheckPermissions, {
  CheckType,
} from './utils/hooks/useCheckPermissions';
import { Permission } from './utils/generated/graphql';
import { useApollo } from './utils/hooks/useApollo';
import Version from './app.json';
import HighlanderProvider from './components/HighlanderProvider/HighlanderProvider';
import { rebuildCache } from './utils/rebuildCache';
import PushNotifications from './components/PushNotifications/PushNotifications';

interface IProtectedRoute {
  permissions: Permission[];
  children?: ReactNode;
}

const ProtectedRoute = ({ permissions, children }: IProtectedRoute) => {
  const hasAccess = useCheckPermissions(permissions, CheckType.PARTIAL);

  if (!hasAccess) {
    return <Navigate to="/" />;
  }
  return children ? children : <Outlet />;
};

function App() {
  useDarkMode();

  const loggedIn = isUserLoggedIn();
  const apolloClient = useApollo();

  useEffect(() => {
    const metaAppName = document.createElement('meta');
    metaAppName.setAttribute('name', 'LH_APPNAME');
    metaAppName.setAttribute('content', APP_NAME);
    document.head.appendChild(metaAppName);

    const metaAppVersion = document.createElement('meta');
    metaAppVersion.setAttribute('name', 'LH_APPVERSION');
    metaAppVersion.setAttribute('content', Version.version ?? 'none');
    document.head.appendChild(metaAppVersion);
    rebuildCache();
  }, []);

  return (
    <>
      {apolloClient && (
        <>
          <ApolloProvider client={apolloClient}>
            <AppVersion isHidden={true} />
            <Mqtt loggedIn={loggedIn}>
              <UserSettings loggedIn={loggedIn}>
                <HighlanderProvider>
                  <Router>
                    <Routes>
                      {loggedIn ? (
                        <Route path="/" element={<Layout />}>
                          <Route index element={<Home />} />
                          <Route path="/home" element={<Home />} />
                          <Route
                            path="/search"
                            element={
                              <ProtectedRoute
                                permissions={[
                                  Permission.SEARCH_FLIGHTS,
                                  Permission.SEARCH_FLIGHTS_BY_CARRIER,
                                ]}
                              />
                            }>
                            <Route
                              path=""
                              element={<FlightSearchContainer />}
                            />
                            <Route
                              path=":flight/process"
                              element={<Process />}
                            />
                            <Route
                              path=":flight/above"
                              element={
                                <ProtectedRoute
                                  permissions={[
                                    Permission.DISPLAY_ABOVE_THE_WING_PAGE,
                                  ]}>
                                  <AboveTheWing />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path=":flight/below"
                              element={
                                <ProtectedRoute
                                  permissions={[
                                    Permission.DISPLAY_BELOW_THE_WING_PAGE,
                                  ]}>
                                  <BelowTheWing />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path=":flight/team"
                              element={
                                <ProtectedRoute
                                  permissions={[Permission.DISPLAY_TEAM_PAGE]}>
                                  <Team />
                                </ProtectedRoute>
                              }
                            />
                          </Route>
                          <Route
                            path="/favourites"
                            element={
                              <ProtectedRoute
                                permissions={[Permission.MANAGE_MY_FLIGHTS]}
                              />
                            }>
                            <Route path="" element={<Favourites />} />
                            <Route
                              path=":flight/process"
                              element={<Process />}
                            />
                            <Route
                              path=":flight/above"
                              element={
                                <ProtectedRoute
                                  permissions={[
                                    Permission.DISPLAY_ABOVE_THE_WING_PAGE,
                                  ]}>
                                  <AboveTheWing />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path=":flight/below"
                              element={
                                <ProtectedRoute
                                  permissions={[
                                    Permission.DISPLAY_BELOW_THE_WING_PAGE,
                                  ]}>
                                  <BelowTheWing />
                                </ProtectedRoute>
                              }
                            />
                            <Route
                              path=":flight/team"
                              element={
                                <ProtectedRoute
                                  permissions={[Permission.DISPLAY_TEAM_PAGE]}>
                                  <Team />
                                </ProtectedRoute>
                              }
                            />
                          </Route>
                          <Route path="/settings" element={<Settings />} />
                          <Route
                            path="/dummyPush"
                            element={<PushNotifications />}
                          />
                          {process?.env?.REACT_APP_TAC_ADMIN_PAGE_FEATURE_DISABLED?.toUpperCase() !==
                            'TRUE' && (
                            <Route
                              path="/admin"
                              element={
                                <ProtectedRoute
                                  permissions={[
                                    Permission.READ_ALL_NOTIFICATIONS,
                                    Permission.READ_STATION_NOTIFICATIONS,
                                  ]}>
                                  <Admin />
                                </ProtectedRoute>
                              }
                            />
                          )}
                        </Route>
                      ) : (
                        <Route index element={<Login />} />
                      )}
                      <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                  </Router>
                </HighlanderProvider>
              </UserSettings>
            </Mqtt>
          </ApolloProvider>
        </>
      )}
    </>
  );
}

export default App;
