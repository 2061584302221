export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AboveTheWing = {
  __typename?: 'AboveTheWing';
  handBaggages?: Maybe<HandBaggages>;
  passengerSpecials?: Maybe<PassengerSpecials>;
  passengerStatus?: Maybe<PassengerStatus>;
  passengerStatusByClass?: Maybe<PassengerStatusByClass>;
  totalCounters?: Maybe<TotalCounters>;
};

export type AboveTheWingOutput = {
  __typename?: 'AboveTheWingOutput';
  data?: Maybe<AboveTheWing>;
  errors?: Maybe<Array<Maybe<DataError>>>;
};

export type AddStationsInput = {
  stations?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AddStationsOutput = {
  __typename?: 'AddStationsOutput';
  stations?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type Aircraft = {
  __typename?: 'Aircraft';
  aircraftType?: Maybe<Scalars['String']['output']>;
};

export type AircraftRotation = {
  __typename?: 'AircraftRotation';
  data?: Maybe<InboundFlight>;
  errors?: Maybe<Array<Maybe<DataError>>>;
};

export type ApplicationChanges = {
  __typename?: 'ApplicationChanges';
  dbVersion?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
};

export type BaggageList = {
  __typename?: 'BaggageList';
  ULD?: Maybe<Scalars['String']['output']>;
  category: Scalars['String']['output'];
  pos: Scalars['String']['output'];
  securityNumber: Scalars['String']['output'];
  status: Scalars['String']['output'];
  tagNumber: Scalars['String']['output'];
};

export type BaggageListInput = {
  flightId: Scalars['String']['input'];
  localStation: Scalars['String']['input'];
  order?: InputMaybe<Scalars['String']['input']>;
  sortBy: Scalars['String']['input'];
};

export type BaggageListOutput = {
  __typename?: 'BaggageListOutput';
  data?: Maybe<Array<Maybe<BaggageList>>>;
  errors?: Maybe<Array<Maybe<DataError>>>;
};

export type BaggageStatus = {
  __typename?: 'BaggageStatus';
  checkedIn: Scalars['Int']['output'];
  loaded: Scalars['Int']['output'];
  offload: Scalars['Int']['output'];
};

export type BelowTheWing = {
  __typename?: 'BelowTheWing';
  baggageStatus: BaggageStatus;
  flightId: Scalars['String']['output'];
  specialLoads: SpecialLoads;
};

export type BelowTheWingInput = {
  flightId: Scalars['String']['input'];
  localStation: Scalars['String']['input'];
};

export type BelowTheWingOutput = {
  __typename?: 'BelowTheWingOutput';
  data?: Maybe<BelowTheWing>;
  errors?: Maybe<Array<Maybe<DataError>>>;
};

export type BestTimeLogic = {
  __typename?: 'BestTimeLogic';
  delay?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['String']['output']>;
  time12format?: Maybe<Scalars['String']['output']>;
  timeLeft?: Maybe<Scalars['String']['output']>;
  timeLogicUsed?: Maybe<Scalars['String']['output']>;
  timeString?: Maybe<Scalars['String']['output']>;
};

export type BoardingReason = {
  __typename?: 'BoardingReason';
  id?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  single?: Maybe<Scalars['String']['output']>;
};

export type BroadcastRoles = {
  __typename?: 'BroadcastRoles';
  roleGroup: RoleGroup;
  roleName: Scalars['String']['output'];
  targetRoles: Array<TargetRole>;
};

export type CabinClass = {
  __typename?: 'CabinClass';
  accepted: Scalars['Int']['output'];
  available: Scalars['Int']['output'];
  boarded: Scalars['Int']['output'];
  booked: Scalars['Int']['output'];
  capacity: Scalars['Int']['output'];
  standBy: Scalars['Int']['output'];
};

export type CacheTelephonyEntry = {
  __typename?: 'CacheTelephonyEntry';
  roles?: Maybe<Array<CachedRoles>>;
  station: Scalars['String']['output'];
};

export type CachedAirportInfo = {
  __typename?: 'CachedAirportInfo';
  airportCode: Scalars['String']['output'];
};

export type CachedCheckedInFlight = {
  __typename?: 'CachedCheckedInFlight';
  aircraft?: Maybe<Aircraft>;
  countdown?: Maybe<CountDown>;
  departureInfo: CachedDepartureInfo;
  flightId: Scalars['String']['output'];
  flightStatus: FlightStatus;
  timezonePackets?: Maybe<Array<TimezonePacket>>;
};

export type CachedDepartureInfo = {
  __typename?: 'CachedDepartureInfo';
  airportLocation: CachedAirportInfo;
  bestDepartureTime?: Maybe<Scalars['String']['output']>;
  scheduledDepartureTime?: Maybe<Scalars['String']['output']>;
};

export type CachedFlight = {
  __typename?: 'CachedFlight';
  actualOffBlockTime?: Maybe<Scalars['String']['output']>;
  aircraft?: Maybe<Aircraft>;
  countdown?: Maybe<CountDown>;
  departureInfo?: Maybe<CachedDepartureInfo>;
  flightId?: Maybe<Scalars['String']['output']>;
  flightStatus: FlightStatus;
  registration?: Maybe<Scalars['String']['output']>;
  timezonePackets?: Maybe<Array<TimezonePacket>>;
};

export type CachedMyFlight = {
  __typename?: 'CachedMyFlight';
  aircraft?: Maybe<Aircraft>;
  countdown?: Maybe<CountDown>;
  departureInfo: CachedDepartureInfo;
  flightId: Scalars['String']['output'];
  flightStatus: FlightStatus;
  isCheckedIn: Scalars['Boolean']['output'];
  isFavourite: Scalars['Boolean']['output'];
  notificationMuted?: Maybe<Scalars['Boolean']['output']>;
  timezonePackets?: Maybe<Array<TimezonePacket>>;
};

export type CachedRoles = {
  __typename?: 'CachedRoles';
  roleName: Scalars['String']['output'];
  users: Array<CachedUsers>;
};

export type CachedRow = {
  __typename?: 'CachedRow';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<CachedFlight>;
};

export type CachedUsers = {
  __typename?: 'CachedUsers';
  externalID: Scalars['String']['output'];
  externalUniqueID: Scalars['String']['output'];
  group: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
};

export type ChatConversation = {
  __typename?: 'ChatConversation';
  chatId: Scalars['String']['output'];
  flightId: Scalars['String']['output'];
  flightName: Scalars['String']['output'];
  lastConversationId: Scalars['String']['output'];
  lastTopic: Scalars['String']['output'];
  messages: Array<ChatMessage>;
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  body?: Maybe<Scalars['String']['output']>;
  conversationId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  isMe?: Maybe<Scalars['Boolean']['output']>;
  messageId?: Maybe<Scalars['String']['output']>;
  newUserJoined?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type ChatMessagesOutput = {
  __typename?: 'ChatMessagesOutput';
  conversations: Array<ChatConversation>;
  lastStatusUpdate?: Maybe<Scalars['String']['output']>;
};

export enum ChatUpdateType {
  NEW_MESSAGE = 'NEW_MESSAGE',
  UPDATED_MESSAGE = 'UPDATED_MESSAGE'
}

export type CheckInFlight = {
  __typename?: 'CheckInFlight';
  _id: Scalars['String']['output'];
  flightId: Scalars['String']['output'];
  isChatAvailable: Scalars['Boolean']['output'];
  role: Role;
  userId: Scalars['String']['output'];
};

export type CheckInFlightInput = {
  checkInInput: FlightInput;
};

export type CheckInFlightOutput = {
  __typename?: 'CheckInFlightOutput';
  checkedInRoles?: Maybe<Array<Maybe<CheckedInResponsableRole>>>;
  errors?: Maybe<Array<Maybe<DataError>>>;
  flight?: Maybe<CheckInFlight>;
};

export enum CheckInSource {
  EXTERNAL = 'EXTERNAL',
  EXTERNAL_IGCC = 'EXTERNAL_IGCC',
  INTERNAL = 'INTERNAL'
}

export type CheckOutFlightInput = {
  checkOutInput: FlightIdInput;
};

export type CheckOutFlightOutput = {
  __typename?: 'CheckOutFlightOutput';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CheckedInResponsableRole = {
  __typename?: 'CheckedInResponsableRole';
  canCall: Scalars['Boolean']['output'];
  canChat: Scalars['Boolean']['output'];
  checkedInUsers: Array<CheckedInUser>;
  isChatAvailable: Scalars['Boolean']['output'];
  isHighlander: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  roleGroup: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
};

export type CheckedInUser = {
  __typename?: 'CheckedInUser';
  phone: Scalars['String']['output'];
  technicalRole: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type Connection = {
  __typename?: 'Connection';
  Inbound?: Maybe<Scalars['String']['output']>;
  arrival?: Maybe<Scalars['String']['output']>;
  arrivalTimeZoneObject?: Maybe<TimeObject>;
  checkedInLuggage?: Maybe<Scalars['Int']['output']>;
  checkedInPassengers?: Maybe<Scalars['Int']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  gate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
};

export type CountDown = {
  __typename?: 'CountDown';
  timeLogicUsed?: Maybe<Scalars['String']['output']>;
};

export type CrewDetailsOutput = {
  __typename?: 'CrewDetailsOutput';
  data?: Maybe<TeamDetail>;
  errors?: Maybe<Array<Maybe<DataError>>>;
};

export type CrewMember = {
  __typename?: 'CrewMember';
  arrivalTime?: Maybe<BestTimeLogic>;
  assignedFlightId?: Maybe<Scalars['String']['output']>;
  assignmentType?: Maybe<Scalars['String']['output']>;
  employeeRole?: Maybe<EmployeeRole>;
  gate?: Maybe<Scalars['String']['output']>;
  inbound?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  pos?: Maybe<Scalars['String']['output']>;
  totalCrewMembers?: Maybe<Scalars['Int']['output']>;
};

export type DataError = {
  __typename?: 'DataError';
  code?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type DeleteNotificationOutput = {
  __typename?: 'DeleteNotificationOutput';
  deleted: Scalars['Boolean']['output'];
};

export type DepartureDetails = {
  __typename?: 'DepartureDetails';
  departureTime?: Maybe<BestTimeLogic>;
  gate?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Position>;
  type?: Maybe<Scalars['String']['output']>;
};

export type DetailsHeaderData = {
  __typename?: 'DetailsHeaderData';
  MCD?: Maybe<Scalars['String']['output']>;
  actualOffBlockTime?: Maybe<Scalars['String']['output']>;
  aircraftId?: Maybe<Scalars['String']['output']>;
  arrivalAirport?: Maybe<Scalars['String']['output']>;
  arrivalTimes?: Maybe<TimeObject>;
  bestTimeLogic?: Maybe<BestTimeLogic>;
  boardingNotReady?: Maybe<Scalars['Boolean']['output']>;
  calculatedTakeOffTime?: Maybe<TimeObject>;
  departureAirport?: Maybe<Scalars['String']['output']>;
  departureTimes?: Maybe<TimeObject>;
  etdDelay?: Maybe<FlightEtd>;
  flightId?: Maybe<Scalars['String']['output']>;
  flightName?: Maybe<Scalars['String']['output']>;
  flightStatus?: Maybe<Scalars['String']['output']>;
  gate?: Maybe<Scalars['String']['output']>;
  isCheckedIn?: Maybe<Scalars['Boolean']['output']>;
  isFavourite?: Maybe<Scalars['Boolean']['output']>;
  mostPenalisingRegulationCause?: Maybe<MostPenalisingRegulationCause>;
  newBoardingTime?: Maybe<TimeObject>;
  pos?: Maybe<Scalars['String']['output']>;
  registration?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  showMCD: Scalars['Boolean']['output'];
  targetOffBlockTime?: Maybe<TimeObject>;
  targetStartAprovalTime?: Maybe<TimeObject>;
  type?: Maybe<Scalars['String']['output']>;
};

export type DetailsHeaderInput = {
  flightId: Scalars['String']['input'];
  scope?: InputMaybe<Scalars['String']['input']>;
};

export type DetailsHeaderOutput = {
  __typename?: 'DetailsHeaderOutput';
  data?: Maybe<DetailsHeaderData>;
  errors?: Maybe<Array<Maybe<DataError>>>;
};

export type EmployeeRole = {
  __typename?: 'EmployeeRole';
  code: Scalars['String']['output'];
  roleDescription: Scalars['String']['output'];
};

export type Error = {
  __typename?: 'Error';
  error?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type EtdFlightUpdateInput = {
  delayCodes: Array<IFlightEtd>;
  estimatedDepartureTime: Scalars['String']['input'];
  flightId: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  registration: Scalars['String']['input'];
  standardDepartureTime: Scalars['String']['input'];
};

export type FlightCheckinRolesOutput = {
  __typename?: 'FlightCheckinRolesOutput';
  flightId: Scalars['String']['output'];
  myRole?: Maybe<Role>;
  roles: Array<Maybe<CheckedInResponsableRole>>;
};

export type FlightDetails = {
  __typename?: 'FlightDetails';
  arrivalAirport?: Maybe<Scalars['String']['output']>;
  departureAirport?: Maybe<Scalars['String']['output']>;
  flightName?: Maybe<Scalars['String']['output']>;
  flightStatus?: Maybe<Scalars['String']['output']>;
};

export type FlightEtd = {
  __typename?: 'FlightEtd';
  codes: Array<FlightEtdCode>;
};

export type FlightEtdCode = {
  __typename?: 'FlightEtdCode';
  code: Scalars['String']['output'];
  delay: Scalars['Int']['output'];
  subcode: Scalars['String']['output'];
};

export type FlightIdInput = {
  flightId: Scalars['String']['input'];
};

export type FlightInput = {
  flightId: Scalars['String']['input'];
  role: Scalars['String']['input'];
  source?: InputMaybe<CheckInSource>;
};

export type FlightSearch = {
  __typename?: 'FlightSearch';
  arrivalTimes?: Maybe<TimeObject>;
  closestDeparture?: Maybe<Scalars['Boolean']['output']>;
  departureDetails?: Maybe<DepartureDetails>;
  departureTimes?: Maybe<TimeObject>;
  flightDetails?: Maybe<FlightDetails>;
  flightId?: Maybe<Scalars['String']['output']>;
  isCheckedIn?: Maybe<Scalars['Boolean']['output']>;
  isFavourite?: Maybe<Scalars['Boolean']['output']>;
  scheduledDepartureTime?: Maybe<Scalars['String']['output']>;
};

export enum FlightStatus {
  CANCELLED = 'CANCELLED',
  DEFAULT = 'DEFAULT',
  DEPARTED = 'DEPARTED'
}

export type FlightsSearchOutput = {
  __typename?: 'FlightsSearchOutput';
  data?: Maybe<Array<FlightSearch>>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type GanttData = {
  __typename?: 'GanttData';
  delayEnd?: Maybe<Scalars['String']['output']>;
  delayStart?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['String']['output']>;
};

export type HandBaggages = {
  __typename?: 'HandBaggages';
  cob: Scalars['Int']['output'];
  critical: Scalars['Boolean']['output'];
  hbag: Scalars['Int']['output'];
};

export type IFlightEtd = {
  code: Scalars['String']['input'];
  subcode?: InputMaybe<Scalars['String']['input']>;
};

export type IataDelayCode = {
  __typename?: 'IataDelayCode';
  carrier?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  subcode?: Maybe<Scalars['String']['output']>;
};

export type InboundConnections = {
  __typename?: 'InboundConnections';
  connections: Array<Maybe<Connection>>;
  inboundLuggage: Scalars['Int']['output'];
  inboundPassengers: Scalars['Int']['output'];
};

export type InboundConnectionsOutput = {
  __typename?: 'InboundConnectionsOutput';
  data?: Maybe<InboundConnections>;
  errors?: Maybe<Array<Maybe<DataError>>>;
};

export type InboundFlight = {
  __typename?: 'InboundFlight';
  arrival?: Maybe<Scalars['String']['output']>;
  arrivalTimes?: Maybe<TimeObject>;
  bestTimeLogic?: Maybe<TimeDetails>;
  departure?: Maybe<Scalars['String']['output']>;
  departureTimes?: Maybe<TimeObject>;
  gate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  inbound?: Maybe<Scalars['String']['output']>;
  pos?: Maybe<Scalars['String']['output']>;
};

export type Infants = {
  __typename?: 'Infants';
  accepted: Scalars['Int']['output'];
  boarded: Scalars['Int']['output'];
  booked: Scalars['Int']['output'];
  standBy: Scalars['Int']['output'];
};

export type KafkaStatus = {
  __typename?: 'KafkaStatus';
  consumerGroup?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ServiceStatus>;
};

export type MarkAsReadInput = {
  chatId: Scalars['String']['input'];
  conversationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  lastStatusUpdate: Scalars['String']['input'];
};

export type MarkChatAsReadOutput = {
  __typename?: 'MarkChatAsReadOutput';
  errors?: Maybe<Array<Maybe<DataError>>>;
  success: Scalars['Boolean']['output'];
};

export type MarkUserNotificationsAsReadInput = {
  ids: Array<Scalars['String']['input']>;
  updateBy: UpdateBy;
};

export type MarkUserNotificationsAsReadOutput = {
  __typename?: 'MarkUserNotificationsAsReadOutput';
  data?: Maybe<Array<UserNotification>>;
  error?: Maybe<DataError>;
};

export enum MessageStatus {
  DELIVERED = 'DELIVERED',
  FAILED = 'FAILED',
  READ = 'READ',
  SENT = 'SENT'
}

export type MostPenalisingRegulationCause = {
  __typename?: 'MostPenalisingRegulationCause';
  iataDelayCode?: Maybe<Scalars['String']['output']>;
  locationCategory?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addMyFlight?: Maybe<MyFlightsAddOutput>;
  addStation?: Maybe<AddStationsOutput>;
  addUserSettings?: Maybe<UserSettingsOutput>;
  checkInFlight?: Maybe<CheckInFlightOutput>;
  checkOutFlight?: Maybe<CheckOutFlightOutput>;
  createNotification?: Maybe<Array<Maybe<Notification>>>;
  deleteNotification?: Maybe<DeleteNotificationOutput>;
  deleteUserSettings?: Maybe<UserSettingsDeleteOutput>;
  markChatAsRead?: Maybe<MarkChatAsReadOutput>;
  markUserNotificationsAsRead?: Maybe<MarkUserNotificationsAsReadOutput>;
  removeMyFlight?: Maybe<MyFlightsRemoveOutput>;
  sendChatMessage?: Maybe<SendChatMessageOutput>;
  updateBoardingTime?: Maybe<UpdateBoardingTimeOutput>;
  updateChat?: Maybe<UpdateChatOutput>;
  updateEtdForFlight: SetEtdForFlightOutput;
  updateMyFlight?: Maybe<MyFlightsUpdateOutput>;
  updateNotification?: Maybe<Notification>;
  updateTelephonyBook: UploadTelephonyBookOutput;
  updateUserSettings?: Maybe<UserSettingsUpdateOutput>;
};


export type MutationAddMyFlightArgs = {
  myFlights: MyFlightsAddRemoveInput;
};


export type MutationAddStationArgs = {
  stationsList: AddStationsInput;
};


export type MutationAddUserSettingsArgs = {
  userSettings: UserSettingsInput;
};


export type MutationCheckInFlightArgs = {
  flight: CheckInFlightInput;
};


export type MutationCheckOutFlightArgs = {
  flight: CheckOutFlightInput;
};


export type MutationCreateNotificationArgs = {
  input: NotificationInput;
};


export type MutationDeleteNotificationArgs = {
  notificationId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteUserSettingsArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationMarkChatAsReadArgs = {
  input: MarkAsReadInput;
};


export type MutationMarkUserNotificationsAsReadArgs = {
  notifications: MarkUserNotificationsAsReadInput;
};


export type MutationRemoveMyFlightArgs = {
  myFlights: MyFlightsAddRemoveInput;
};


export type MutationSendChatMessageArgs = {
  input?: InputMaybe<SendChatMessageInput>;
};


export type MutationUpdateBoardingTimeArgs = {
  input: UpdateBoardingTimeInput;
};


export type MutationUpdateChatArgs = {
  conversationId?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationUpdateEtdForFlightArgs = {
  input: EtdFlightUpdateInput;
};


export type MutationUpdateMyFlightArgs = {
  myFlights: MyFlightsUpdateInput;
};


export type MutationUpdateNotificationArgs = {
  input: NotificationUpdateInput;
};


export type MutationUpdateTelephonyBookArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationUpdateUserSettingsArgs = {
  sourceLocation: UpdateSettingsLocations;
  userSettings: UserSettingsInput;
};

export type MyFlight = {
  __typename?: 'MyFlight';
  arrivalTimes?: Maybe<TimeObject>;
  countdown?: Maybe<BestTimeLogic>;
  departureTimes?: Maybe<TimeObject>;
  destination?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  flightId?: Maybe<Scalars['String']['output']>;
  flightName?: Maybe<Scalars['String']['output']>;
  flightStatus?: Maybe<Scalars['String']['output']>;
  gate?: Maybe<Scalars['String']['output']>;
  isCheckedIn?: Maybe<Scalars['Boolean']['output']>;
  isFavourite?: Maybe<Scalars['Boolean']['output']>;
  notificationMuted?: Maybe<Scalars['Boolean']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  pos?: Maybe<Scalars['String']['output']>;
  processes?: Maybe<Scalars['Int']['output']>;
  registration?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
  type?: Maybe<Scalars['String']['output']>;
};

export type MyFlightUpdate = {
  checkedInRole?: InputMaybe<Scalars['String']['input']>;
  flightId: Scalars['String']['input'];
  isCheckedIn: Scalars['Boolean']['input'];
  isFavourite: Scalars['Boolean']['input'];
  notificationMuted: Scalars['Boolean']['input'];
};

export type MyFlightUpdateResponse = {
  __typename?: 'MyFlightUpdateResponse';
  checkedInRole?: Maybe<Scalars['String']['output']>;
  checkedInRoles?: Maybe<Array<Maybe<CheckedInResponsableRole>>>;
  flightId: Scalars['String']['output'];
  isCheckedIn: Scalars['Boolean']['output'];
  isFavourite: Scalars['Boolean']['output'];
  notificationMuted: Scalars['Boolean']['output'];
  role?: Maybe<Role>;
};

export type MyFlightsAddOutput = {
  __typename?: 'MyFlightsAddOutput';
  errors?: Maybe<Array<Maybe<DataError>>>;
  flights?: Maybe<Array<Scalars['String']['output']>>;
};

export type MyFlightsAddRemoveInput = {
  flights?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MyFlightsOutput = {
  __typename?: 'MyFlightsOutput';
  data?: Maybe<Array<MyFlight>>;
  errors?: Maybe<Array<Maybe<DataError>>>;
};

export type MyFlightsRemoveOutput = {
  __typename?: 'MyFlightsRemoveOutput';
  removedFlights?: Maybe<Array<Scalars['String']['output']>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type MyFlightsUpdateInput = {
  flights?: InputMaybe<Array<MyFlightUpdate>>;
};

export type MyFlightsUpdateOutput = {
  __typename?: 'MyFlightsUpdateOutput';
  errors?: Maybe<Array<Maybe<DataError>>>;
  updatedFlights: Array<MyFlightUpdateResponse>;
};

export type Notification = {
  __typename?: 'Notification';
  _id: Scalars['String']['output'];
  category: NotificationCategory;
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  details: NotificationDetails;
  sentAt?: Maybe<Scalars['Date']['output']>;
  status?: Maybe<NotificationStatusType>;
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: NotificationType;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export enum NotificationCategory {
  AIRCRAFT_CHANGE = 'AIRCRAFT_CHANGE',
  ATC_READY = 'ATC_READY',
  BAD_WEATHER = 'BAD_WEATHER',
  BOARDING_CLOSED = 'BOARDING_CLOSED',
  BOARDING_READY = 'BOARDING_READY',
  BOARDING_READY_TIME = 'BOARDING_READY_TIME',
  DEPARTED = 'DEPARTED',
  FLIGHT_CANCELATION = 'FLIGHT_CANCELATION',
  FLIGHT_DELAYED = 'FLIGHT_DELAYED',
  GATE_CHANGE = 'GATE_CHANGE',
  GENERAL = 'GENERAL',
  MAINTENANCE = 'MAINTENANCE',
  NEW_BOARDING = 'NEW_BOARDING',
  NEW_DEPLOY = 'NEW_DEPLOY',
  NEW_TOBT = 'NEW_TOBT',
  NEW_VERSION = 'NEW_VERSION',
  OFF_BLOCK = 'OFF_BLOCK',
  POSITION_CHANGE = 'POSITION_CHANGE',
  RAMP_CLOSED = 'RAMP_CLOSED',
  STRIKE = 'STRIKE',
  UNKNOWN = 'UNKNOWN'
}

export type NotificationDetails = {
  __typename?: 'NotificationDetails';
  createdBy?: Maybe<Scalars['String']['output']>;
  flightId?: Maybe<Scalars['String']['output']>;
  schedule?: Maybe<NotificationScheduleDetails>;
  senderRole?: Maybe<RoleGroup>;
  station?: Maybe<Scalars['String']['output']>;
  targetRoles?: Maybe<Array<Maybe<RoleGroup>>>;
  times?: Maybe<NotificationTimeDetails>;
};

export type NotificationDetailsInput = {
  flightId?: InputMaybe<Scalars['String']['input']>;
  schedule?: InputMaybe<NotificationScheduleInput>;
  senderRole?: InputMaybe<RoleGroup>;
  stations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  targetRoles?: InputMaybe<Array<RoleGroup>>;
};

export type NotificationInput = {
  category: NotificationCategory;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<NotificationDetailsInput>;
  subtitle: Scalars['String']['input'];
  topic?: InputMaybe<Scalars['String']['input']>;
  type: NotificationType;
};

export type NotificationOutput = {
  __typename?: 'NotificationOutput';
  data?: Maybe<Array<UserNotification>>;
  error?: Maybe<DataError>;
};

export type NotificationScheduleDetails = {
  __typename?: 'NotificationScheduleDetails';
  deleteDate?: Maybe<Scalars['String']['output']>;
  sendDate?: Maybe<Scalars['String']['output']>;
};

export type NotificationScheduleInput = {
  deleteDate?: InputMaybe<Scalars['String']['input']>;
  sendDate?: InputMaybe<Scalars['String']['input']>;
};

export enum NotificationStatus {
  ALL = 'all',
  READ = 'read',
  UNREAD = 'unread'
}

export enum NotificationStatusType {
  SCHEDULED = 'SCHEDULED',
  SENT = 'SENT',
  TO_BE_DELETED = 'TO_BE_DELETED'
}

export type NotificationTimeDetails = {
  __typename?: 'NotificationTimeDetails';
  local12?: Maybe<Scalars['String']['output']>;
  local24?: Maybe<Scalars['String']['output']>;
  utc12?: Maybe<Scalars['String']['output']>;
  utc24?: Maybe<Scalars['String']['output']>;
};

export enum NotificationType {
  FLIGHT = 'FLIGHT',
  ROLE = 'ROLE',
  STATION = 'STATION',
  SYSTEM = 'SYSTEM'
}

export type NotificationTypes = {
  __typename?: 'NotificationTypes';
  categories: Array<NotificationCategory>;
  type: NotificationType;
};

export type NotificationTypesOutput = {
  __typename?: 'NotificationTypesOutput';
  broadcastRoles?: Maybe<Array<Maybe<BroadcastRoles>>>;
  types: Array<NotificationTypes>;
};

export type NotificationUpdateInput = {
  _id: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<NotificationDetailsInput>;
  subtitle: Scalars['String']['input'];
};

export type OutboundConnection = {
  __typename?: 'OutboundConnection';
  checkedInLuggage?: Maybe<Scalars['Int']['output']>;
  checkedInPassengers?: Maybe<Scalars['Int']['output']>;
  departure?: Maybe<Scalars['String']['output']>;
  departureTimeZoneObject?: Maybe<TimeObject>;
  gate?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  outbound?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
};

export type OutboundConnections = {
  __typename?: 'OutboundConnections';
  connections: Array<Maybe<OutboundConnection>>;
  outboundLuggage: Scalars['Int']['output'];
  outboundPassengers: Scalars['Int']['output'];
};

export type OutboundConnectionsOutput = {
  __typename?: 'OutboundConnectionsOutput';
  data?: Maybe<OutboundConnections>;
  errors?: Maybe<Array<Maybe<DataError>>>;
};

export type PassengerSpecials = {
  __typename?: 'PassengerSpecials';
  DEPA?: Maybe<Scalars['Int']['output']>;
  DEPU?: Maybe<Scalars['Int']['output']>;
  MEDA_MEQT?: Maybe<Scalars['Int']['output']>;
  STRC?: Maybe<Scalars['Int']['output']>;
  StandBy?: Maybe<Scalars['Int']['output']>;
  UMNR?: Maybe<Scalars['Int']['output']>;
  WCHC?: Maybe<Scalars['Int']['output']>;
  WCHR?: Maybe<Scalars['Int']['output']>;
  WCHS?: Maybe<Scalars['Int']['output']>;
};

export type PassengerStatus = {
  __typename?: 'PassengerStatus';
  available: Scalars['Int']['output'];
  boarded: Scalars['Int']['output'];
  booked: Scalars['Int']['output'];
  capacity: Scalars['Int']['output'];
  checkedIn: Scalars['Int']['output'];
  jumps: Scalars['Int']['output'];
  missing: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PassengerStatusByClass = {
  __typename?: 'PassengerStatusByClass';
  businessClass?: Maybe<CabinClass>;
  economyClass?: Maybe<CabinClass>;
  firstClass?: Maybe<CabinClass>;
  infants?: Maybe<Infants>;
  premiumEconomyClass?: Maybe<CabinClass>;
};

export enum Permission {
  BRIEFING_PACKAGE = 'BRIEFING_PACKAGE',
  CHAT_GROUP = 'CHAT_GROUP',
  CHAT_READ = 'CHAT_READ',
  CHAT_WRITE = 'CHAT_WRITE',
  CHECK_IN = 'CHECK_IN',
  CREATE_ALL_NOTIFICATIONS = 'CREATE_ALL_NOTIFICATIONS',
  CREATE_ROLE_NOTIFICATIONS = 'CREATE_ROLE_NOTIFICATIONS',
  CREATE_STATION_NOTIFICATIONS = 'CREATE_STATION_NOTIFICATIONS',
  DELETE_ALL_NOTIFICATIONS = 'DELETE_ALL_NOTIFICATIONS',
  DELETE_OWN_STATION_NOTIFICATIONS = 'DELETE_OWN_STATION_NOTIFICATIONS',
  DELETE_ROLE_NOTIFICATIONS = 'DELETE_ROLE_NOTIFICATIONS',
  DELETE_STATION_NOTIFICATIONS = 'DELETE_STATION_NOTIFICATIONS',
  DISPLAY_ABOVE_THE_WING_PAGE = 'DISPLAY_ABOVE_THE_WING_PAGE',
  DISPLAY_BELOW_THE_WING_PAGE = 'DISPLAY_BELOW_THE_WING_PAGE',
  DISPLAY_HOMEPAGE_LINKS = 'DISPLAY_HOMEPAGE_LINKS',
  DISPLAY_MYLINK_DSM = 'DISPLAY_MYLINK_DSM',
  DISPLAY_MYLINK_FEEDBACK = 'DISPLAY_MYLINK_FEEDBACK',
  DISPLAY_MYLINK_FLOAT = 'DISPLAY_MYLINK_FLOAT',
  DISPLAY_MYLINK_LH_ALTEA = 'DISPLAY_MYLINK_LH_ALTEA',
  DISPLAY_MYLINK_LOOK = 'DISPLAY_MYLINK_LOOK',
  DISPLAY_MYLINK_LX_ALTEA = 'DISPLAY_MYLINK_LX_ALTEA',
  DISPLAY_NOTIFICATIONS = 'DISPLAY_NOTIFICATIONS',
  DISPLAY_PROCESS_PAGE_ABOVE_WING_DATA = 'DISPLAY_PROCESS_PAGE_ABOVE_WING_DATA',
  DISPLAY_PROCESS_PAGE_BELOW_WING_DATA = 'DISPLAY_PROCESS_PAGE_BELOW_WING_DATA',
  DISPLAY_PROCESS_PAGE_STATUS_DATA = 'DISPLAY_PROCESS_PAGE_STATUS_DATA',
  DISPLAY_PROCESS_PAGE_TIMES_DATA = 'DISPLAY_PROCESS_PAGE_TIMES_DATA',
  DISPLAY_TEAM_PAGE = 'DISPLAY_TEAM_PAGE',
  MANAGE_MY_FLIGHTS = 'MANAGE_MY_FLIGHTS',
  READ_ALL_NOTIFICATIONS = 'READ_ALL_NOTIFICATIONS',
  READ_PHONE_BOOK_GENERAL = 'READ_PHONE_BOOK_GENERAL',
  READ_ROLE_NOTIFICATIONS = 'READ_ROLE_NOTIFICATIONS',
  READ_STATION_NOTIFICATIONS = 'READ_STATION_NOTIFICATIONS',
  RECEIVE_FLIGHT_NOTIFICATIONS = 'RECEIVE_FLIGHT_NOTIFICATIONS',
  RECEIVE_ROLE_NOTIFICATIONS = 'RECEIVE_ROLE_NOTIFICATIONS',
  RECEIVE_STATION_NOTIFICATIONS = 'RECEIVE_STATION_NOTIFICATIONS',
  RECEIVE_SYSTEM_NOTIFICATIONS = 'RECEIVE_SYSTEM_NOTIFICATIONS',
  SEARCH_FLIGHTS = 'SEARCH_FLIGHTS',
  SEARCH_FLIGHTS_BY_CARRIER = 'SEARCH_FLIGHTS_BY_CARRIER',
  TELEPHONY = 'TELEPHONY',
  TELEPHONY_SEARCH = 'TELEPHONY_SEARCH',
  TELEPHONY_STATION = 'TELEPHONY_STATION',
  UNKNOWN = 'UNKNOWN',
  UPDATE_ALL_NOTIFICATIONS = 'UPDATE_ALL_NOTIFICATIONS',
  UPDATE_BOARDING_TIME = 'UPDATE_BOARDING_TIME',
  UPDATE_ETD = 'UPDATE_ETD',
  UPDATE_OWN_STATION_NOTIFICATIONS = 'UPDATE_OWN_STATION_NOTIFICATIONS',
  UPDATE_ROLE_NOTIFICATIONS = 'UPDATE_ROLE_NOTIFICATIONS',
  UPDATE_STATION_NOTIFICATIONS = 'UPDATE_STATION_NOTIFICATIONS'
}

export type Position = {
  __typename?: 'Position';
  value?: Maybe<Scalars['String']['output']>;
};

export type Process = {
  __typename?: 'Process';
  detailsGantt?: Maybe<Array<Maybe<GanttData>>>;
  detailsHeader?: Maybe<DetailsHeaderData>;
  processes?: Maybe<ProcessesData>;
};

export type ProcessOutput = {
  __typename?: 'ProcessOutput';
  data?: Maybe<Process>;
  errors?: Maybe<Array<Maybe<DataError>>>;
};

export type ProcessesData = {
  __typename?: 'ProcessesData';
  acceptance?: Maybe<Scalars['String']['output']>;
  boarding?: Maybe<Scalars['String']['output']>;
  fueling?: Maybe<Scalars['String']['output']>;
  loadControl?: Maybe<Scalars['String']['output']>;
  ramp?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  aboveTheWing?: Maybe<AboveTheWingOutput>;
  aircraftRotation?: Maybe<AircraftRotation>;
  baggageList?: Maybe<BaggageListOutput>;
  belowTheWing?: Maybe<BelowTheWingOutput>;
  cachedAllFlights?: Maybe<Array<Maybe<CachedRow>>>;
  cachedCheckedInFlights: Array<CachedCheckedInFlight>;
  cachedMyFlights: Array<CachedMyFlight>;
  cachedTelephonyBook?: Maybe<Array<Maybe<CacheTelephonyEntry>>>;
  chatMessages?: Maybe<ChatMessagesOutput>;
  detailsCrew?: Maybe<CrewDetailsOutput>;
  detailsHeader?: Maybe<DetailsHeaderOutput>;
  etdDelayCodes: Array<Maybe<IataDelayCode>>;
  flightCheckedInRoles?: Maybe<Array<Maybe<FlightCheckinRolesOutput>>>;
  flightSearch?: Maybe<FlightsSearchOutput>;
  getUserSettings?: Maybe<UserSettingsOutput>;
  inboundConnections?: Maybe<InboundConnectionsOutput>;
  myFlights?: Maybe<MyFlightsOutput>;
  notificationTypes: NotificationTypesOutput;
  notifications?: Maybe<Array<Notification>>;
  notificationsHistory?: Maybe<NotificationOutput>;
  outboundConnections?: Maybe<OutboundConnectionsOutput>;
  process?: Maybe<ProcessOutput>;
  savePushSubscription?: Maybe<SavePushSubscriptionOutput>;
  searchTelephony?: Maybe<TelephonySearchOutput>;
  sendPushNotification?: Maybe<SendPushNotificationOutput>;
  stations?: Maybe<Array<Maybe<Station>>>;
  systemStatus?: Maybe<SystemStatus>;
  updateBoardingReasons?: Maybe<UpdateBoardingReasons>;
  version?: Maybe<Scalars['String']['output']>;
};


export type QueryAboveTheWingArgs = {
  flightId: Scalars['String']['input'];
};


export type QueryAircraftRotationArgs = {
  flightId: Scalars['String']['input'];
};


export type QueryBaggageListArgs = {
  flightInfo: BaggageListInput;
};


export type QueryBelowTheWingArgs = {
  flightInfo: BelowTheWingInput;
};


export type QueryCachedTelephonyBookArgs = {
  stationsList?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryChatMessagesArgs = {
  lastStatusUpdate?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  type: ChatUpdateType;
};


export type QueryDetailsCrewArgs = {
  flightId: Scalars['String']['input'];
};


export type QueryDetailsHeaderArgs = {
  flightInfo: DetailsHeaderInput;
};


export type QueryEtdDelayCodesArgs = {
  carrier: Scalars['String']['input'];
};


export type QueryFlightCheckedInRolesArgs = {
  flightId: Scalars['String']['input'];
  flightName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFlightSearchArgs = {
  genericQuery?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInboundConnectionsArgs = {
  flightId: Scalars['String']['input'];
};


export type QueryMyFlightsArgs = {
  flightId?: InputMaybe<Scalars['String']['input']>;
  stationsList?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryNotificationsArgs = {
  type?: InputMaybe<Array<NotificationType>>;
};


export type QueryNotificationsHistoryArgs = {
  status?: InputMaybe<NotificationStatus>;
};


export type QueryOutboundConnectionsArgs = {
  flightId: Scalars['String']['input'];
};


export type QueryProcessArgs = {
  flightId: Scalars['String']['input'];
};


export type QuerySavePushSubscriptionArgs = {
  subscription: SavePushSubscriptionInput;
};


export type QuerySearchTelephonyArgs = {
  searchValue: Scalars['String']['input'];
};


export type QueryUpdateBoardingReasonsArgs = {
  carrier?: InputMaybe<Scalars['String']['input']>;
};

export type Role = {
  __typename?: 'Role';
  _id: Scalars['String']['output'];
  carrier: Scalars['String']['output'];
  isHighlander: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  permissions: Array<Permission>;
  roleGroup: RoleGroup;
  shortName?: Maybe<Scalars['String']['output']>;
  technicalRole: Scalars['String']['output'];
};

export enum RoleGroup {
  ADMIN = 'ADMIN',
  BASE = 'BASE',
  CABIN = 'CABIN',
  CABINFA = 'CABINFA',
  CABINPU = 'CABINPU',
  COCKPIT = 'COCKPIT',
  COCKPITFO = 'COCKPITFO',
  FSB = 'FSB',
  FUELINGSUPERVISOR = 'FUELINGSUPERVISOR',
  GATE = 'GATE',
  GENERIC = 'GENERIC',
  HUBCONTROL = 'HUBCONTROL',
  LOADCONTROL = 'LOADCONTROL',
  MANAGERONDUTY = 'MANAGERONDUTY',
  OCC = 'OCC',
  PSM = 'PSM',
  RAMP = 'RAMP',
  RAMPSUPERVISOR = 'RAMPSUPERVISOR',
  SECDESK = 'SECDESK',
  STATION_MANAGEMENT = 'STATION_MANAGEMENT',
  TURNAROUNDCOORDINATOR = 'TURNAROUNDCOORDINATOR',
  UNKNOWN = 'UNKNOWN'
}

export type SavePushSubscriptionInput = {
  endpoint: Scalars['String']['input'];
  keys: SubscriptionKeys;
};

export type SavePushSubscriptionOutput = {
  __typename?: 'SavePushSubscriptionOutput';
  errors: Array<Maybe<DataError>>;
  success: Scalars['Boolean']['output'];
};

export type SendChatMessageInput = {
  body: Scalars['String']['input'];
  chatId: Scalars['String']['input'];
  lastConversationId?: InputMaybe<Scalars['String']['input']>;
};

export type SendChatMessageOutput = {
  __typename?: 'SendChatMessageOutput';
  errors?: Maybe<Array<Maybe<DataError>>>;
  messageId?: Maybe<Scalars['String']['output']>;
  status: MessageStatus;
};

export type SendPushNotificationOutput = {
  __typename?: 'SendPushNotificationOutput';
  errors: Array<Maybe<DataError>>;
  success: Scalars['Boolean']['output'];
};

export enum ServiceStatus {
  OFF = 'OFF',
  ON = 'ON'
}

export type SetEtdForFlightOutput = {
  __typename?: 'SetEtdForFlightOutput';
  errors: Array<DataError>;
  status: Scalars['Boolean']['output'];
};

export type SpecialLoads = {
  __typename?: 'SpecialLoads';
  AVIH: Scalars['Int']['output'];
  DGR: Scalars['Int']['output'];
  STRL: Scalars['Int']['output'];
  WCH: Scalars['Int']['output'];
  WEA: Scalars['Int']['output'];
  other: Scalars['Int']['output'];
};

export type Station = {
  __typename?: 'Station';
  notificationMuted?: Maybe<Scalars['Boolean']['output']>;
  stationCode: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type SubscriptionKeys = {
  auth: Scalars['String']['input'];
  p256dh: Scalars['String']['input'];
};

export type SystemStatus = {
  __typename?: 'SystemStatus';
  applicationChanges?: Maybe<Array<ApplicationChanges>>;
  kafkaCache: KafkaStatus;
  kafkaNotifications: KafkaStatus;
  kafkaProducer: KafkaStatus;
  mqtt: ServiceStatus;
  podName: Scalars['String']['output'];
  system: ServiceStatus;
};

export type TargetRole = {
  __typename?: 'TargetRole';
  roleGroup: RoleGroup;
  roleName: Scalars['String']['output'];
};

export type TeamDetail = {
  __typename?: 'TeamDetail';
  cabin: Scalars['Int']['output'];
  cockpit: Scalars['Int']['output'];
  deadhead: Scalars['Int']['output'];
  deadheadCrewAssignments: Array<Maybe<CrewMember>>;
  onDutyCrewAssignments: Array<Maybe<CrewMember>>;
};

export type TelephonySearchOutput = {
  __typename?: 'TelephonySearchOutput';
  checkinRoles?: Maybe<Array<Maybe<FlightCheckinRolesOutput>>>;
  stations?: Maybe<Array<Maybe<CacheTelephonyEntry>>>;
  type: TelephonySearchType;
};

export enum TelephonySearchType {
  FLIGHT = 'FLIGHT',
  STATION = 'STATION'
}

export type TimeDetails = {
  __typename?: 'TimeDetails';
  arrivalDateTime: Scalars['String']['output'];
  arrivalString: Scalars['String']['output'];
  arrivalTime: Scalars['String']['output'];
  arrivalTime12format: Scalars['String']['output'];
};

export type TimeObject = {
  __typename?: 'TimeObject';
  UTCDiff?: Maybe<Scalars['String']['output']>;
  UTCTime?: Maybe<Scalars['String']['output']>;
  airportCode?: Maybe<Scalars['String']['output']>;
  bestTimeLocal?: Maybe<Scalars['String']['output']>;
  bestTimeLocal12format?: Maybe<Scalars['String']['output']>;
  bestTimeUTC?: Maybe<Scalars['String']['output']>;
  bestTimeUTC12format?: Maybe<Scalars['String']['output']>;
  localTime?: Maybe<Scalars['String']['output']>;
};

export type TimezonePacket = {
  __typename?: 'TimezonePacket';
  airportcode?: Maybe<Scalars['String']['output']>;
  utc?: Maybe<Scalars['String']['output']>;
};

export type TotalCounters = {
  __typename?: 'TotalCounters';
  totalLuggage: Scalars['Int']['output'];
  totalPassengers: Scalars['Int']['output'];
};

export type UpdateBoardingReasons = {
  __typename?: 'UpdateBoardingReasons';
  carrier: Scalars['String']['output'];
  reasons: Array<BoardingReason>;
};

export type UpdateBoardingTimeInput = {
  boardingNotReadyTime: Scalars['Date']['input'];
  boardingTimeEstimated: Scalars['Date']['input'];
  flightId: Scalars['String']['input'];
  reasons: Array<Scalars['String']['input']>;
};

export type UpdateBoardingTimeOutput = {
  __typename?: 'UpdateBoardingTimeOutput';
  errors: Array<DataError>;
  timeUpdated: Scalars['Boolean']['output'];
};

export enum UpdateBy {
  NOTIFICATION = 'NOTIFICATION',
  USER_NOTIFICATION = 'USER_NOTIFICATION'
}

export type UpdateChatOutput = {
  __typename?: 'UpdateChatOutput';
  errors?: Maybe<Array<Maybe<DataError>>>;
  updateSent?: Maybe<Scalars['Boolean']['output']>;
};

export enum UpdateSettingsLocations {
  HOME = 'HOME',
  LOGIN = 'LOGIN',
  SETTINGS = 'SETTINGS'
}

export type UploadTelephonyBookOutput = {
  __typename?: 'UploadTelephonyBookOutput';
  errors?: Maybe<Array<Maybe<DataError>>>;
  success: Scalars['Boolean']['output'];
};

export type UserNotification = {
  __typename?: 'UserNotification';
  _id: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  notification: Notification;
  read?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type UserSettingsDeleteOutput = {
  __typename?: 'UserSettingsDeleteOutput';
  deleted?: Maybe<Scalars['Boolean']['output']>;
};

export type UserSettingsInput = {
  chatMuted: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  favoriteFlightsMuted: Scalars['Boolean']['input'];
  firstName: Scalars['String']['input'];
  isPhoneReminderActive: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  nativeNotificationsMuted?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumber: Scalars['String']['input'];
  station: Scalars['String']['input'];
  stationMuted: Scalars['Boolean']['input'];
  stationsList: Array<Scalars['String']['input']>;
  theme: Scalars['String']['input'];
  use24: Scalars['Boolean']['input'];
  useUTC: Scalars['Boolean']['input'];
  userName: Scalars['String']['input'];
};

export type UserSettingsOutput = {
  __typename?: 'UserSettingsOutput';
  chatMuted?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  favoriteFlightsMuted?: Maybe<Scalars['Boolean']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  isPhoneReminderActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nativeNotificationsMuted?: Maybe<Scalars['Boolean']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  pkNumber?: Maybe<Scalars['String']['output']>;
  roles: Array<Role>;
  station?: Maybe<Scalars['String']['output']>;
  stationMuted?: Maybe<Scalars['Boolean']['output']>;
  stationsList: Array<Scalars['String']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
  use24?: Maybe<Scalars['Boolean']['output']>;
  useUTC?: Maybe<Scalars['Boolean']['output']>;
  userId: Scalars['String']['output'];
  userName?: Maybe<Scalars['String']['output']>;
};

export type UserSettingsUpdateOutput = {
  __typename?: 'UserSettingsUpdateOutput';
  data: UserSettingsOutput;
  errors?: Maybe<Array<Maybe<DataError>>>;
};
