import { gql } from '@apollo/client';

export const SAVE_PUSH_SUBSCRIPTION = gql`
  query SavePushSubscription($subscription: SavePushSubscriptionInput!) {
    savePushSubscription(subscription: $subscription) {
      success
      errors {
        code
        message
        description
      }
    }
  }
`;

export const SEND_PUSH_NOTIFICATION = gql`
  query SendPushNotification {
    sendPushNotification {
      success
      errors {
        code
        message
        description
      }
    }
  }
`;
