import { useLazyQuery } from '@apollo/client';
import {
  Query,
  SavePushSubscriptionInput,
  SavePushSubscriptionOutput,
} from '../generated/graphql';
import { IDataResponse } from '../types';
import { SAVE_PUSH_SUBSCRIPTION } from '../../graphql/pushNotifications/pushNotifications.queries';

interface ISavePushSubscriptionLazy
  extends IDataResponse<SavePushSubscriptionOutput> {
  onSavePushSubscription: (
    subscription: SavePushSubscriptionInput
  ) => Promise<SavePushSubscriptionOutput>;
}
export const useSubscribeToPushLazy = (): ISavePushSubscriptionLazy => {
  const [savePushSubscription, { data, loading, error }] = useLazyQuery<
    Pick<Query, 'savePushSubscription'>
  >(SAVE_PUSH_SUBSCRIPTION);

  const onSavePushSubscription = async (
    subscription: SavePushSubscriptionInput
  ) => {
    const result = await savePushSubscription({
      variables: {
        subscription,
      },
    });

    const emptyResult: SavePushSubscriptionOutput = {
      success: false,
      errors: [],
    };
    return result?.data?.savePushSubscription ?? emptyResult;
  };
  return {
    data: data?.savePushSubscription ?? null,
    loading,
    error,
    onSavePushSubscription,
  };
};
